import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-app-screenshots",
    templateUrl: "./app-screenshots.component.html",
    styleUrls: ["./app-screenshots.component.scss"],
})
export class AppScreenshotsComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() images: ['../assets/img/screenshot/1.png','assets/img/screenshot/2.png'];

    constructor() {}

  ngOnInit(): void {
      
    }
}
