<div class="container">
    <div class="section-title">
        <h2>Frequently Asked Questions</h2>
        <div class="bar"></div>
        <p>
            Got a question? Check here first! If you don't find your answers
            here don't hesitate to reach out and ask!
        </p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                What is a SAAS?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseOne"
                            class="collapse show"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Software as a Service means we build it and
                                    keep it running. We give you access for
                                    running your business but we take care of
                                    the technical stuff. When you no longer want
                                    to use your app or website its a no hassle
                                    departure.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                What marketing services do you offer?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseTwo"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Depending on your package we provide Google
                                    ranking, Social Media management, SEO and
                                    more. Included with the basic hosting is
                                    Basic Google My Business optimization.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                Do I get support?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseThree"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Full support is included in the monthly
                                    subscription. Support is handled through our
                                    ticket system. More details can be provided
                                    after a consultation.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFour">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                            >
                                Do you offer hosting?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseFour"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Yes, hosting is included in our packages.
                                    Maintenance and configuration normally an
                                    extra charge is also included also included.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFive">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                How long does it take?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseFive"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    It depends. For simple project 3-5 days is
                                    not uncommon. For custom work this is billed
                                    month to month but a feature is expected to
                                    take a couple weeks to a couple months
                                    depending on complexity. We will try to
                                    provide estimates when we discuss project
                                    details.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-image">
                <img src="assets/img/faq-mobile.png" alt="image" />
            </div>
        </div>
    </div>
</div>
