import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BookingComponent } from "../booking/booking.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    @Input() title1: string;
    @Input() title2: string;
    @Input() paragraph1: string;
    @Input() paragraph2: string;
    @Input() paragraph3: string;
    @Input() paragraph4: string;
    @Input() buttonTitle: string;

    public bookCallForm = this.formBuilder.group({
        date: "",
        firstName: "",
        lastName: "",
        phone: "",
    });

    constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

    ngOnInit(): void {}

    onBookCallSubmit(): void {
        this.openDialog(this.bookCallForm);
    }

    openDialog(form: FormGroup): void {
        let dialogRef = this.dialog.open(BookingComponent, {
            width: "80%",
            height: "80%",
            data: form.value,
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
        });
    }
}
