import { Component, Inject, ViewChild } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ContactService } from "src/app/services/contact.service";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import * as moment from "moment";
import { ThemePalette } from "@angular/material/core";
import {
    NgxMatDateAdapter,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { NgxMatMomentAdapter, NgxMatMomentModule } from "@angular-material-components/moment-adapter";
@Component({
    selector: "app-booking",
    templateUrl: "./booking.component.html",
    styleUrls: ["./booking.component.scss"],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatDatepickerModule,
        NgxMatMomentModule,
    ],
    providers: [MatDatepickerModule, NgxMatMomentModule],
})
export class BookingComponent {
    @ViewChild("picker") picker: any;

    public date: moment.Moment;
    public disabled = false;
    public showSpinners = true;
    public showSeconds = false;
    public touchUi = false;
    public enableMeridian = false;
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public color: ThemePalette = "primary";

    public bookCallForm = this.formBuilder.group({
        date: new FormControl(null, [Validators.required]),
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        name: "",
        subject: "",
        message: "",
        bookCall: true,
    });
    constructor(
        private formBuilder: FormBuilder,
        private contact: ContactService,
        public dialogRef: MatDialogRef<BookingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        //this.date = new Date(2021, 9, 4, 5, 6, 7);
    }

    onSubmit(form: FormGroup): void {
        this.contact.book(form.value);
        this.bookCallForm.reset();
        this.dialogRef.close();
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
