import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
    providedIn: "root",
})
export class MessagingService {
    constructor(private snackBar: MatSnackBar) {}

    snackbar(msg: string, config?: MatSnackBarConfig): void {
        const configSnackBar: MatSnackBarConfig = config
            ? config
            : {
                  duration: 10000,
              };
        this.snackBar.open(msg, "OK", configSnackBar);
    }
}
