<form [formGroup]="bookCallForm" (ngSubmit)="onSubmit(bookCallForm)">
    <div class="container">
        <mat-form-field>
            <input
                matInput
                placeholder="First Name"
                formControlName="firstName"
                type="text"
            />
        </mat-form-field>

        <mat-form-field>
            <input
                matInput
                placeholder="Last Name"
                formControlName="lastName"
                type="text"
            />
        </mat-form-field>

        <mat-form-field>
            <input
                matInput
                placeholder="Email"
                formControlName="email"
                type="email"
            />
        </mat-form-field>

        <mat-form-field>
            <input
                matInput
                placeholder="Phone"
                formControlName="phone"
                type="tel"
            />
        </mat-form-field>

     
<mat-form-field>
  <input matInput [ngxMatDatetimePicker]="picker" placeholder="When" formControlName="date"
    [min]="minDate" [max]="maxDate" >
  <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
    [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
    [color]="color" [enableMeridian]="enableMeridian" >
  </ngx-mat-datetime-picker>
</mat-form-field>

        <button mat-raised-button type="submit">Submit</button>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
    </div>
</form>
