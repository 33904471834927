import { Component } from '@angular/core';

@Component({
  selector: 'app-home-print',
  templateUrl: './home-print.component.html',
  styleUrls: ['./home-print.component.scss']
})
export class HomePrintComponent {

}
