import { Component } from '@angular/core';

@Component({
  selector: 'app-home-website',
  templateUrl: './home-website.component.html',
  styleUrls: ['./home-website.component.scss']
})
export class HomeWebsiteComponent {

}
