import { Component, Input, OnInit } from "@angular/core";

export class Iconographic {
    title: string;
    icon: string;
    description: string;
}
@Component({
    selector: "app-features",
    templateUrl: "./features.component.html",
    styleUrls: ["./features.component.scss"],
})
export class FeaturesComponent implements OnInit {
    @Input() title: string;
    @Input() description: string;
    @Input() iconographics: Iconographic[] = [];

    constructor() {}

    ngOnInit(): void {}
}
