import { Component } from "@angular/core";
import { Iconographic } from "../../common/features/features.component";
import { Testimonial } from "../../common/testimonials/testimonials.component";

@Component({
    selector: "app-home-app",
    templateUrl: "./home-app.component.html",
    styleUrls: ["./home-app.component.scss"],
})
export class HomeAppComponent {
    title1 = "About our Apps";
    paragraph1 = `Using one code base, we produce apps that work for ios, android, web and desktop at the same time. With AWS, your app will live in a serverless cloud making it fast and infinitely scalable. We build 'em to last, so when your MVP has served its purpose, don't throw it grow it!`;
    title2 = "Making it Painless";
    paragraph2 = `We'll brand your app with your colors and logo. Don't have a logo or color palette? No problem! We can help you with that.`;
    paragraph3 = `In fact, we'll host it for you, register your domain and even get you work mail if you need it. As a full-service web media shop have many great add-ons available. If you have something in mind just ask!`;

    testimonial_title = "Testimonials";
    testimonial_description =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.";
    testimonial_quotes: Testimonial[] = [
        {
            person: "Alan Blackford",
            position: "COO at PNW Brands",
            quote: `Technology changes quickly, so you need people who are flexible with their tools, flexible with direction, flexible with priorities and ever changing demands. That's what you get with Matt, and that's why I highly recommend him to any company wanting to improve the technology in their organization!`,
            img: "assets/img/client/2.jpg",
        },
        {
            person: "Lucas Phan",
            position: "Director of Software Development at Bitwise Industries",
            quote: `I highly recommend Matt to any organization seeking a talented and motivated developer. His technical proficiency, collaborative nature, and commitment to excellence make him an exceptional addition to any team. I have no doubt that he will excel in his future endeavors.`,
            img: "assets/img/client/4.jpg",
        },
        {
            person: "Yoda",
            position: "COO at PNW Brands",
            quote: `Technology changes quickly, so you need people who are flexible with their tools, flexible with direction, flexible with priorities and ever changing demands. That's what you get with Matt, and that's why I highly recommend him to any company wanting to improve the technology in their organization!`,
            img: "assets/img/client/3.jpg",
        },
        {
            person: "Vader",
            position: "COO at PNW Brands",
            quote: `Technology changes quickly, so you need people who are flexible with their tools, flexible with direction, flexible with priorities and ever changing demands. That's what you get with Matt, and that's why I highly recommend him to any company wanting to improve the technology in their organization!`,
            img: "assets/img/client/1.jpg",
        },
        {
            person: "Alan Blackford",
            position: "COO at PNW Brands",
            quote: `Technology changes quickly, so you need people who are flexible with their tools, flexible with direction, flexible with priorities and ever changing demands. That's what you get with Matt, and that's why I highly recommend him to any company wanting to improve the technology in their organization!`,
            img: "assets/img/client/2.jpg",
        },
        {
            person: "Lucas Phan",
            position: "Director of Software Development at Bitwise Industries",
            quote: `I highly recommend Matt to any organization seeking a talented and motivated developer. His technical proficiency, collaborative nature, and commitment to excellence make him an exceptional addition to any team. I have no doubt that he will excel in his future endeavors.`,
            img: "assets/img/client/4.jpg",
        },
        {
            person: "Yoda",
            position: "COO at PNW Brands",
            quote: `Technology changes quickly, so you need people who are flexible with their tools, flexible with direction, flexible with priorities and ever changing demands. That's what you get with Matt, and that's why I highly recommend him to any company wanting to improve the technology in their organization!`,
            img: "assets/img/client/3.jpg",
        },
        {
            person: "Vader",
            position: "COO at PNW Brands",
            quote: `Technology changes quickly, so you need people who are flexible with their tools, flexible with direction, flexible with priorities and ever changing demands. That's what you get with Matt, and that's why I highly recommend him to any company wanting to improve the technology in their organization!`,
            img: "assets/img/client/1.jpg",
        },
    ];
    screenshot_title = "App Screenshot";
    screenshot_description = "Lorem ipsum dolor sit amet";
    screenshots = [
        "assets/img/screenshot/1.png",
        "assets/img/screenshot/2.png",
        "assets/img/screenshot/3.png",
        "assets/img/screenshot/4.png",
        "assets/img/screenshot/5.png",
        "assets/img/screenshot/6.png",
        "assets/img/screenshot/1.png",
        "assets/img/screenshot/2.png",
        "assets/img/screenshot/3.png",
    ];
    features_title = "Awesome Features";
    features_description =
        "We have a number of popular, pre-designed features to choose from so you get to market faster!";
    iconographics: Iconographic[] = [
        {
            title: "Video Playlist",
            icon: "fa fa-sun",
            description:
                "Show videos and let your users select which videos to watch from a playlist.",
        },
        {
            title: "Maps & Geo Location",
            icon: "fa fa-lightbulb",
            description:
                "With Google Maps & Geo location, let your users explore what's out there.",
        },
        {
            title: "Social Networking",
            icon: "fa fa-desktop",
            description:
                "User profiles with friends and messaging system allows users to mingle.",
        },
        {
            title: "Products Catalogue",
            icon: "fa fa-code",
            description:
                "Display and search a catalogue that can be used as part of an e-commerce app!",
        },
        {
            title: "Retina Ready",
            icon: "fa fa-eye",
            description:
                "Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.",
        },
        {
            title: "Unlimited Features",
            icon: "fa fa-camera",
            description:
                "Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.",
        },
    ];
}
