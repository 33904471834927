<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="overview-content">
                <h3>SEO means Business</h3>
                <div class="bar"></div>
                <p>Websites and apps can be your best kept secret...without proper SEO! We can optimize your Google Business Listing and improve your search ranking and rating through SEO, and that means business for you!</p>
                <br />
                <p>Our packages are affordable and pack a punch. Give us a call to see how we can help you grow your business today!</p>
                <div class="overview-btn">
                    <a href="https://coatneymedia.mailchimpsites.com/book-online" target="_blank" class="default-btn">Book A Call <span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="overview-image">
                <img src="assets/img/overview.png" alt="image">
            </div>
        </div>
    </div>
</div>