import { Component } from '@angular/core';

@Component({
  selector: 'app-home-seo',
  templateUrl: './home-seo.component.html',
  styleUrls: ['./home-seo.component.scss']
})
export class HomeSeoComponent {

}
