<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><h2>Coatney Media</h2></a>
                    <p>Catch us on social and see what we've been up to and what we're doing next!</p>
                    <ul class="social-list">
                        <li><a href="https://www.facebook.com/profile.php?id=61553610530503" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/coatneymedia" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/matthew-coatney-28b465169/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.instagram.com/coatneymedia/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Company</h3>

                    <!--ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Careers</a></li>
                        <li><a routerLink="/">Awards</a></li>
                        <li><a routerLink="/">User Program</a></li>
                        <li><a routerLink="/">Locations</a></li>
                        <li><a routerLink="/">Login</a></li>
                    </ul-->
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Products</h3>

                    <!--ul class="list">
                        <li><a routerLink="/">Integrations</a></li>
                        <li><a routerLink="/">API</a></li>
                        <li><a routerLink="/">Documentation</a></li>
                        <li><a routerLink="/">Sign Up</a></li>
                        <li><a routerLink="/">Release Notes</a></li>
                        <li><a routerLink="/">CRM</a></li>
                    </ul-->
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Download</h3>
                    
                    <!--ul class="footer-holder">
                        <li><a href="#"><img src="assets/img/store/1.png" alt="image"></a></li>
                        <li><a href="#"><img src="assets/img/store/2.png" alt="image"></a></li>
                    </ul-->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right">
    <div class="container">
        <div class="copy-right-content">
            <p>©2023 Coatney Media, All Rights Reserved</p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>