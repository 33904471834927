<div class="container">
    <div class="section-title">
        <h2>{{ title1 }}</h2>
        <div class="bar"></div>
        <p>
            {{ paragraph1 }}
        </p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>{{ title2 }}</h3>
                <div class="bar"></div>
                <p>
                    {{ paragraph2 }}
                </p>

                <p>
                    {{ paragraph3 }}
                </p>

                <p>
                    {{ paragraph4 }}
                </p>
                <div class="about-btn">
                    <a
                        href="https://coatneymedia.mailchimpsites.com/book-online"
                        target="_blank"
                        class="default-btn"
                        >Book A Call <span></span
                    ></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/about.png" alt="image" />
            </div>
        </div>
    </div>
</div>
