import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
    animal: string;
    name: string;
}
import { ContactService } from "src/app/services/contact.service";
import { BookingComponent } from "../../common/booking/booking.component";

@Component({
    selector: "app-home-services",
    templateUrl: "./home-services.component.html",
    styleUrls: ["./home-services.component.scss"],
})
export class HomeServicesComponent {
    public contactForm = this.formBuilder.group({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        name: "",
        subject: "",
        message: "",
    });

    public bookCallForm = this.formBuilder.group({
        date: "",
        firstName: "",
        lastName: "",
        phone: "",
    });

    title1 = "What We Offer is SAAS!";
    paragraph1 = `Our goal is: Free you to focus on running your business while we focus on growing it. All within a small to medium business budget!`;
    title2 = "The Kitchen Sink";
    paragraph2 = `A website or app requires content like text, graphics and videos to build not to mention making it useful with domain name, hosting, work mail and advertising!`;
    paragraph3 = `Coatney Media can help from start to finish. We develop your app, provide content, graphics, setup domain names, hosting, attract clients and other marketing essentials. All for a fraction of the industry standard cost.`;
    paragraph4 = `You get the whole house for the price of the kitchen sink and then we keep it up for you. In short we make it affordable.`;

    feature_title = "Awsome Features";
    feature_desc = "";
    feature_icons = [];

    constructor(
        private contact: ContactService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog
    ) {}

    onSubmit(form: FormGroup): void {
        this.contact.submit(form.value);
        this.contactForm.reset();
    }

    onBookCallSubmit(): void {
        this.openDialog();
    }

    openDialog(): void {
        let dialogRef = this.dialog.open(BookingComponent, {
            width: "400px",
            height: "600px",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === undefined) console.log("Dialog result is undefined");
            else console.log(result);
        });
    }
}
