import { Injectable } from "@angular/core";
import { APIService } from "../API.service";
import { ValidationService } from "./validation.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MessagingService } from "./messaging.service";
import { ILead } from "../models/lead";

@Injectable({
    providedIn: "root",
})
export class ContactService {
    constructor(
        private api: APIService,
        private validate: ValidationService,
        private message: MessagingService
    ) {}

    book(form: Partial<ILead>) {
        // const form2 = {
        //     firstName: "first",
        //     lastName: "last",
        //     email: "email@address.com",
        //     phone: "2092830855",
        //     scheduledCall: new Date().toISOString(),
        //     bookCall: true,
        //     fullName: "full name",
        //     website: "www.g.g",
        //     address: "123 r street",
        //     birthday: "1/1/1",
        //     notes: "{}",
        //     iquiry: "",
        // };
        console.log(`form input ${JSON.stringify(form, null, 4)}`);
        const snack = {
            success:
                "Call Request successfully submitted.\nYou will receive a confirmation email when approved or to reschedule.",
            fail: "Your request could not be scheduled. Please check formatting.",
        };
        let snackChoice: any;
        const isValid = this.validate.validateForm(form, null);

        if (!isValid) {
            console.log("form invalid");
            this.message.snackbar(snack.fail, null);
            return;
        }
        console.log(new Date('1/1/1 5:11 AM').toISOString());
        this.api
            .CreateLead({
                email: String(form.email),
                fullName: +" " + String(form.lastName),
                firstName: String(form.firstName),
                lastName: String(form.lastName),
                phone: String(form.phone),
                bookCall: true,
                scheduledCall: new Date(form.scheduledCall).toISOString(),
            })
            .then((response) => {
                if (!response) {
                    console.log(
                        "booking had no errors, however server returned empty response."
                    );
                    snackChoice = snack.fail;
                } else {
                    console.log(
                        "booking succeeded: " + JSON.stringify(response.id)
                    );

                    snackChoice = snack.success;
                }
            })
            .catch((error) => {
                console.log(`booking failed.${JSON.stringify(error, null, 4)}`);
                snackChoice = snack.fail;
            })
            .finally(() => {
                console.log("booking process completed.");
                this.message.snackbar(snackChoice, null);
            });
        // this.service
        //     .CreateLead({
        //         firstName: String(form.firstName),
        //         lastName: String(form.lastName),
        //         email: String(form.email),
        //         phone: String(form.phone),
        //         scheduledCall: form.scheduledCall.toISOString(),
        //         bookCall: form.bookCall,
        //     })
        //     .then((response) => {
        //         console.log("booking succeeded." + JSON.stringify(response));
        //     })
        //     .catch((error) => {
        //         console.log("booking failed.");
        //     })
        //     .finally(() => {
        //         console.log("booking process completed.");
        //     });
    }

    submit(form: Partial<ILead>) {
        if (
            !this.validate.validateForm(form, {
                success: "Message successfully sent.",
                fail: "Your message could not be sent. Please check formatting.",
            })
        ) {
            return;
        }

        this.api
            .CreateSubscriber({
                email: String(form.email),
            })
            .then((response) => {
                console.log(
                    "subscription succeeded." + JSON.stringify(response)
                );
            })
            .catch((error) => {
                console.log("subscription failed.");
            })
            .finally(() => {
                console.log("subscription completed.");
            });
    }

    subscribe(
        form: Partial<{
            email: string;
            firstName: string;
            lastName: string;
            phone: string;
        }>
    ) {
        if (
            !this.validate.validateForm(form, {
                success:
                    "You are successfully ssubscribed. Check your email for info!",
                fail: "Your subscription could not be processed. Please check email formatting.",
            })
        ) {
            return;
        }
        this.api
            .CreateSubscriber({
                email: String(form.email),
            })
            .then((response) => {
                console.log(
                    "subscription succeeded." + JSON.stringify(response)
                );
            })
            .catch((error) => {
                console.log("subscription failed.");
            })
            .finally(() => {
                console.log("subscription completed.");
            });
    }
}
