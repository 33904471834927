<div class="container">
    <div class="section-title">
        <h2>{{ title }}</h2>
        <div class="bar"></div>
        <p>{{ description }}</p>
    </div>

    <div class="testimonials-slides">
        <div class="client-feedback">
            <div>
                <div class="item" *ngFor="let quote of quotes">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>{{ quote.quote }}</p>
                    </div>
                </div>
            </div>

            <button class="prev-arrow slick-arrow">
                <i class="fa fa-chevron-left"></i>
            </button>

            <button class="next-arrow slick-arrow">
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>

        <div class="client-thumbnails">
            <div>
                <div class="item" *ngFor="let quote of quotes">
                    <div class="img-fill">
                        <img src="{{ quote.img }}" alt="client" />
                    </div>

                    <div class="title">
                        <h3>{{quote.person}}</h3>
                        <span>{{quote.position}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
