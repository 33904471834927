<div class="container">
    <div class="section-title">
        <h2>Pricing Plan</h2>
        <div class="bar"></div>
        <p>
            Our app & site plans include some level of hosting, maintenance and
            SEO. We provide the Custom pricing for a different experience. Call
            today for a Free Consultation!
        </p>
    </div>

    <div class="tab pricing-list-tab">
        <ul class="tabs">
            <li><a href="#">Mobile App</a></li>
            <li><a href="#">Website</a></li>
            <li><a href="#">SEO</a></li>
        </ul>

        <div class="tab_content">
            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Prototype</h3>
                            </div>

                            <div class="price"><sup>$</sup>500<sub></sub></div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Splash Screen
                                </li>
                                <li><i class="fas fa-check"></i> Menu</li>
                                <li>
                                    <i class="fas fa-check"></i> Home Screen
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Login Screen
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Contact Form
                                </li>
                                <li>
                                    <i class="fa fa-check"></i> Welcome Screens
                                </li>
                                <li>
                                    <i class="fa fa-check"></i> 5 Feature
                                    Screens
                                </li>
                                <li><i class="fa fa-times"></i>Functional</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>MVP</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>3600 <sub></sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Prototype</li>
                                <li>
                                    <i class="fas fa-check"></i> 1 Working
                                    Feature
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Working Login
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 5 Licensed
                                    Images
                                </li>
                                <li><i class="fa fa-check"></i> Functional</li>
                                <li><i class="fas fa-check"></i> Mobile PWA</li>
                                <li>
                                    <i class="fa fa-times"></i> Android Store
                                </li>
                                <li><i class="fa fa-times"></i> IOS Store</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Custom</h3>
                            </div>

                            <div class="price">
                                <sub>from</sub><sup>$</sup>9900 <sub> /mo</sub>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Advanced
                                    Features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Agile
                                    Development
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Data-Driven
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Data
                                    Visualization
                                </li>
                                <li><i class="fas fa-check"></i> Reporting</li>
                                <li>
                                    <i class="fa fa-check"></i> Android Store
                                </li>
                                <li><i class="fa fa-check"></i> IOS Store</li>
                                <li>
                                    <i class="fa fa-check"></i> Self or House
                                    Hosting
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Single Page Sites</h3>
                            </div>

                            <div class="price"><sup>$</sup>250</div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Elegant Landing Page
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Hosted
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrade Options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 5 Licensed
                                    Images
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Google Biz
                                    Setup
                                </li>
                                <li><i class="fas fa-check"></i> 1-3 Days</li>
                                <li><i class="fa fa-times"></i> Maintenance</li>
                                <li>
                                    <i class="fa fa-times"></i> Content Writing
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Template</h3>
                            </div>

                            <div class="price">
                                <sub>from</sub> <sup>$</sup>500
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Select Template
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Standard
                                    Maintenance
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Standard SEO
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Standard
                                    Hosting
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Admin access
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 5 Page design
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Add Pages $100
                                </li>
                                <li>
                                    <i class="fa fa-times"></i> Content Writing
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Custom</h3>
                            </div>

                            <div class="price">
                                <sub>from</sub> <sup>$</sup>3600
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Extra features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Advanced
                                    Features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrate options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Admin access
                                </li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> App design</li>
                                <li>
                                    <i class="fas fa-check"></i> Web
                                    developement
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Content Writing
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Bronze</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>199 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                
                                <li>
                                    <i class="fas fa-check"></i> 15 Keywords
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 4 Content
                                    Articles
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 40 Link Building
                                </li>
                                <li><i class="fas fa-check"></i> Basic Website SEO</li>
                                <li>
                                    <i class="fa fa-check"></i> Conversion
                                    Tracking
                                </li>
                                <li><i class="fa fa-check"></i> Monthly Reporting</li>
                                <li><i class="fa fa-times"></i> Dedicated Project Manager</li>
                                <li><i class="fa fa-times"></i> 2 Promo Content</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Silver</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>249 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                
                                <li>
                                    <i class="fas fa-check"></i> 25 Keywords
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 8 Content
                                    Articles
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Promo Content
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 60 Link Building
                                </li>
                                <li><i class="fas fa-check"></i>Standard Website SEO</li>
                                <li>
                                    <i class="fa fa-check"></i> Conversion
                                    Tracking
                                </li>
                                <li><i class="fa fa-check"></i> Monthly Reporting</li>
                                <li><i class="fa fa-check"></i> Dedicated Project Manager</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Gold</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>349 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                               
                                <li>
                                    <i class="fas fa-check"></i> 35 Keywords
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 12 Content
                                    Articles
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 4 Promo Content
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> 80 Link Building
                                </li>
                                <li><i class="fas fa-check"></i> Premium Website SEO</li>
                                <li>
                                    <i class="fa fa-check"></i> Conversion
                                    Tracking
                                </li>
                                <li><i class="fa fa-check"></i> Monthly Reporting</li>
                                <li><i class="fa fa-check"></i> Dedicated Project Manager</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Learn More <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
