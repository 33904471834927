import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { NavbarComponent } from "./components/common/navbar/navbar.component";
import { SubscribeComponent } from "./components/common/subscribe/subscribe.component";
import { ContactComponent } from "./components/common/contact/contact.component";
import { BlogComponent } from "./components/common/blog/blog.component";
import { AppDownloadComponent } from "./components/common/app-download/app-download.component";
import { TeamComponent } from "./components/common/team/team.component";
import { FaqComponent } from "./components/common/faq/faq.component";
import { PricingComponent } from "./components/common/pricing/pricing.component";
import { OverviewComponent } from "./components/common/overview/overview.component";
import { TestimonialsComponent } from "./components/common/testimonials/testimonials.component";
import { AppScreenshotsComponent } from "./components/common/app-screenshots/app-screenshots.component";
import { FeaturesComponent } from "./components/common/features/features.component";
import { AboutComponent } from "./components/common/about/about.component";
import { FunfactsComponent } from "./components/common/funfacts/funfacts.component";
import { HowItWorksComponent } from "./components/common/how-it-works/how-it-works.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogPageComponent } from "./components/pages/blog-page/blog-page.component";
import { HomeSeoComponent } from "./components/pages/home-seo/home-seo.component";
import { HomePrintComponent } from "./components/pages/home-print/home-print.component";
import { HomeDesignComponent } from "./components/pages/home-design/home-design.component";
import { HomeAppComponent } from "./components/pages/home-app/home-app.component";
import { HomeWebsiteComponent } from "./components/pages/home-website/home-website.component";
import { HomeServicesComponent } from "./components/pages/home-services/home-services.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { MatNativeDateModule } from "@angular/material/core";
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";


@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        SubscribeComponent,
        ContactComponent,
        BlogComponent,
        AppDownloadComponent,
        TeamComponent,
        FaqComponent,
        PricingComponent,
        OverviewComponent,
        TestimonialsComponent,
        AppScreenshotsComponent,
        FeaturesComponent,
        AboutComponent,
        FunfactsComponent,
        HowItWorksComponent,
        BlogDetailsComponent,
        BlogPageComponent,
        HomeSeoComponent,
        HomePrintComponent,
        HomeDesignComponent,
        HomeAppComponent,
        HomeWebsiteComponent,
        HomeServicesComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSnackBarModule,
        MatDialogModule,
        MatNativeDateModule,
        NgxMatMomentModule,
        NgxMatDatetimePickerModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
