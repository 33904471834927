import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ContactService } from "src/app/services/contact.service";

@Component({
    selector: "app-subscribe",
    templateUrl: "./subscribe.component.html",
    styleUrls: ["./subscribe.component.scss"],
})
export class SubscribeComponent {
    public subscribeForm = this.formBuilder.group({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        name: "",
        subject: "",
        message: "",
    });

    constructor(
        private formBuilder: FormBuilder,
        private mailChimp: ContactService
    ) {}

    onSubmit(form: FormGroup): void {
        this.mailChimp.subscribe(form.value);
        this.subscribeForm.reset();
    }
}
