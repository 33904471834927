import { Component, Input, OnInit } from "@angular/core";

export class Testimonial {
    person: string;
    quote: string;
    position: string;
    img: string;
}
@Component({
    selector: "app-testimonials",
    templateUrl: "./testimonials.component.html",
    styleUrls: ["./testimonials.component.scss"],
})
export class TestimonialsComponent implements OnInit {
    @Input() title: string;
    @Input() description: string;
    @Input() quotes: Testimonial[];
    constructor() {}

    ngOnInit(): void {
        this.quotes = [
            {
                person: "Alan Blackford",
                quote: "Matt was initially hired at our startup ecommerce company as an intern. He quickly became such an integral part of our endeavor that we asked him to take over all technology systems.",
                position: "COO PNW Brands",
                img: "assets/img/client/1.jpg",
            },
            {
                person: "Nico DiGiorgio",
                quote: "I'm very pleased with the work we've done together. (NZEO App)",
                position: "CEM, Net Zero Energy Operators",
                img: "assets/img/client/2.jpg",
            },
            // {
            //     person: "Laurel Pickard",
            //     quote: "(Some quote here)",
            //     position: "Owner High Country Health Foods",
            //     img: "",
            // },
            // {
            //     person: "Paul Reyes",
            //     quote: "(Some quote here)",
            //     position: "Co-Owner Workgnome",
            //     img: "",
            // },
        ];
    }
}
