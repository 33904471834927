import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { HomeSeoComponent } from './components/pages/home-seo/home-seo.component';
import { HomeAppComponent } from './components/pages/home-app/home-app.component';
import { HomeDesignComponent } from './components/pages/home-design/home-design.component';
import { HomePrintComponent } from './components/pages/home-print/home-print.component';
import { HomeWebsiteComponent } from './components/pages/home-website/home-website.component';
import { HomeServicesComponent } from './components/pages/home-services/home-services.component';

const routes: Routes = [
    {path: '', component: HomeServicesComponent},
    {path: 'home-app', component: HomeAppComponent},
    {path: 'home-website', component: HomeWebsiteComponent},
    {path: 'home-design', component: HomeDesignComponent},
    {path: 'home-print', component: HomePrintComponent},
    {path: 'home-seo', component: HomeSeoComponent},
    {path: 'blog', component: BlogPageComponent},
    {path: 'single-blog', component: BlogDetailsComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }