<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>We Make Web Happen</h1>
                            <p>
                                We're A Full Service, One-stop Web Media Shop.
                            </p>
                            <div class="banner-btn">
                                <a
                                    href="https://coatneymedia.mailchimpsites.com/book-online"
                                    target="_blank"
                                    class="default-btn"
                                    >Book A Call <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-form">
                            <h3>Get In Touch</h3>

                            <form
                                [formGroup]="contactForm"
                                (ngSubmit)="onSubmit(contactForm)"
                            >
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Name"
                                        formControlName="name"
                                    />
                                </div>

                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        class="form-control"
                                        placeholder="Email"
                                        formControlName="email"
                                    />
                                </div>

                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Subject"
                                        formControlName="subject"
                                    />
                                </div>

                                <div class="form-group mb-3">
                                    <textarea
                                        cols="30"
                                        rows="6"
                                        class="form-control"
                                        placeholder="Message"
                                        formControlName="message"
                                    ></textarea>
                                </div>

                                <button type="submit">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</div>

<!--section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section-->

<!--div  class="main-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <h1>We Make Web Happen</h1>
                    <p>Full service, One-stop web media shop.</p>
                    <form class="banner-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your Email">
                        <button type="submit">Ask About Our Services</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js"></div>
</div-->

<div class="video-area pt-100">
    <app-how-it-works
        [title]="'Who Are We?'"
        [url]="'https://www.youtube.com/watch?v=5xsG8rG5Fkg'"
    ></app-how-it-works>
</div>

<section id="about" class="about-area ptb-100">
    <app-about
        [title1]="title1"
        [paragraph1]="paragraph1"
        [title2]="title2"
        [paragraph2]="paragraph2"
        [paragraph3]="paragraph3"
         [paragraph4]="paragraph4"
    ></app-about>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<!--section id="features" class="features-area pb-70">
    <app-features
        [title]="feature_title"
        [description]="feature_desc"
        [iconographics]="feature_icons"
    ></app-features>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section-->

<app-app-screenshots [images]="['assets/img/screenshot/1.png','assets/img/screenshot/2.png','assets/img/screenshot/3.png','assets/img/screenshot/4.png','assets/img/screenshot/5.png','assets/img/screenshot/6.png']" [title]="'Our Work'" [description]="'Projects weve helped launch'" ></app-app-screenshots>

<section class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<section class="overview-area ptb-100">
    <app-overview></app-overview>
</section>

<section class="pricing-area pt-100 pb-70">
    <app-pricing></app-pricing>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<!--section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section-->

<!--app-app-download></app-app-download-->

<!--section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section-->

<!--section id="blog" class="blog-area pt-100 pb-70">
    <app-blog></app-blog>
</section-->

<!--section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section-->

<app-subscribe></app-subscribe>
