import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MessagingService } from "./messaging.service";

@Injectable({
    providedIn: "root",
})
export class ValidationService {
    constructor(private messagingService: MessagingService) {}

    validateForm(
        form: Partial<{
            email: string;
            name: string;
            subject: string;
            message: string;
            firstName: string;
            lastName: string;
            bookCall: boolean;
            phone: string;
            date: string;
        }>,
        message: Partial<{ success: string; fail: string }>
    ): boolean {
        let fail = false;
        let msg: any;

        fail = !form || !this.isAWSEmail(form.email) ? true : false;
        if (fail) {console.log('email validation failed'); return false;}
        fail = fail && form.phone && !this.isAWSPhone(form.phone) ? true : false;
        if (fail) {console.log('phone1 validation failed'); return false;}
        fail = fail && form.bookCall && !this.isAWSPhone(form.phone)? true : false;
        if (fail) {console.log('phone2 validation failed'); return false;}

        if (message) {
            msg = fail ? message.fail : message.success;
            this.messagingService.snackbar(msg);
        }

        return !fail;
    }

    isAWSPhone = (val: string): boolean => {
        return !!/^\+?\d[\d\s-]+$/.exec(val);
    };

    isAWSEmail = (val: string): boolean => {
        return !!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(
            val
        );
    };

    isAWSURL = (val: string): boolean => {
        try {
            return !!new URL(val);
        } catch {
            return false;
        }
    };

    isAWSDate = (val: string): boolean => {
        return !!/^\d{4}-\d{2}-\d{2}(Z|[+-]\d{2}:\d{2}($|:\d{2}))?$/.exec(val);
    };

    isAWSTime = (val: string): boolean => {
        return !!/^\d{2}:\d{2}(:\d{2}(.\d+)?)?(Z|[+-]\d{2}:\d{2}($|:\d{2}))?$/.exec(
            val
        );
    };

    isAWSDateTime = (val: string): boolean => {
        return !!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(.\d+)?)?(Z|[+-]\d{2}:\d{2}($|:\d{2}))?$/.exec(
            val
        );
    };

    isAWSTimestamp = (val: number): boolean => {
        return !!/^\d+$/.exec(String(val));
    };

    isAWSJSON = (val: string): boolean => {
        try {
            JSON.parse(val);
            return true;
        } catch {
            return false;
        }
    };

    isAWSIPAddress = (val: string): boolean => {
        return !!/((^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$)|(^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?$))$/.exec(
            val
        );
    };
}
