<section id="screenshots" class="screenshot-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>{{title}}</h2>
            <div class="bar"></div>
            <p>{{description}}</p>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme">
            <div class="screenshot-item" *ngFor="let img of images">
                <div class="image">
                    <img src="{{ img }}" alt="image">
                </div>
            </div>
          
        </div>
    </div>
</section>