/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateSubscriber: OnCreateSubscriberSubscription;
  onUpdateSubscriber: OnUpdateSubscriberSubscription;
  onDeleteSubscriber: OnDeleteSubscriberSubscription;
  onCreateLead: OnCreateLeadSubscription;
  onUpdateLead: OnUpdateLeadSubscription;
  onDeleteLead: OnDeleteLeadSubscription;
};

export type CreateSubscriberInput = {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
};

export type ModelSubscriberConditionInput = {
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  address?: ModelStringInput | null;
  birthday?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelSubscriberConditionInput | null> | null;
  or?: Array<ModelSubscriberConditionInput | null> | null;
  not?: ModelSubscriberConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type Subscriber = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSubscriberInput = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
};

export type DeleteSubscriberInput = {
  id: string;
};

export type CreateLeadInput = {
  id?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
};

export type ModelLeadConditionInput = {
  fullName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  website?: ModelStringInput | null;
  address?: ModelStringInput | null;
  birthday?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  iquiry?: ModelStringInput | null;
  bookCall?: ModelBooleanInput | null;
  scheduledCall?: ModelStringInput | null;
  and?: Array<ModelLeadConditionInput | null> | null;
  or?: Array<ModelLeadConditionInput | null> | null;
  not?: ModelLeadConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Lead = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateLeadInput = {
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
};

export type DeleteLeadInput = {
  id: string;
};

export type ModelSubscriberFilterInput = {
  id?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  address?: ModelStringInput | null;
  birthday?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelSubscriberFilterInput | null> | null;
  or?: Array<ModelSubscriberFilterInput | null> | null;
  not?: ModelSubscriberFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSubscriberConnection = {
  __typename: "ModelSubscriberConnection";
  items: Array<Subscriber | null>;
  nextToken?: string | null;
};

export type ModelLeadFilterInput = {
  id?: ModelIDInput | null;
  fullName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  website?: ModelStringInput | null;
  address?: ModelStringInput | null;
  birthday?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  iquiry?: ModelStringInput | null;
  bookCall?: ModelBooleanInput | null;
  scheduledCall?: ModelStringInput | null;
  and?: Array<ModelLeadFilterInput | null> | null;
  or?: Array<ModelLeadFilterInput | null> | null;
  not?: ModelLeadFilterInput | null;
};

export type ModelLeadConnection = {
  __typename: "ModelLeadConnection";
  items: Array<Lead | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionSubscriberFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  address?: ModelSubscriptionStringInput | null;
  birthday?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSubscriberFilterInput | null> | null;
  or?: Array<ModelSubscriptionSubscriberFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionLeadFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  fullName?: ModelSubscriptionStringInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  website?: ModelSubscriptionStringInput | null;
  address?: ModelSubscriptionStringInput | null;
  birthday?: ModelSubscriptionStringInput | null;
  notes?: ModelSubscriptionStringInput | null;
  iquiry?: ModelSubscriptionStringInput | null;
  bookCall?: ModelSubscriptionBooleanInput | null;
  scheduledCall?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionLeadFilterInput | null> | null;
  or?: Array<ModelSubscriptionLeadFilterInput | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type CreateSubscriberMutation = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSubscriberMutation = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSubscriberMutation = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateLeadMutation = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateLeadMutation = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteLeadMutation = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetSubscriberQuery = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSubscribersQuery = {
  __typename: "ModelSubscriberConnection";
  items: Array<{
    __typename: "Subscriber";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    phone?: string | null;
    address?: string | null;
    birthday?: string | null;
    status?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetLeadQuery = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListLeadsQuery = {
  __typename: "ModelLeadConnection";
  items: Array<{
    __typename: "Lead";
    id: string;
    fullName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    phone?: string | null;
    website?: string | null;
    address?: string | null;
    birthday?: string | null;
    notes?: string | null;
    iquiry?: string | null;
    bookCall?: boolean | null;
    scheduledCall?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateSubscriberSubscription = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSubscriberSubscription = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSubscriberSubscription = {
  __typename: "Subscriber";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  address?: string | null;
  birthday?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateLeadSubscription = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateLeadSubscription = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteLeadSubscription = {
  __typename: "Lead";
  id: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phone?: string | null;
  website?: string | null;
  address?: string | null;
  birthday?: string | null;
  notes?: string | null;
  iquiry?: string | null;
  bookCall?: boolean | null;
  scheduledCall?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateSubscriber(
    input: CreateSubscriberInput,
    condition?: ModelSubscriberConditionInput
  ): Promise<CreateSubscriberMutation> {
    const statement = `mutation CreateSubscriber($input: CreateSubscriberInput!, $condition: ModelSubscriberConditionInput) {
        createSubscriber(input: $input, condition: $condition) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubscriberMutation>response.data.createSubscriber;
  }
  async UpdateSubscriber(
    input: UpdateSubscriberInput,
    condition?: ModelSubscriberConditionInput
  ): Promise<UpdateSubscriberMutation> {
    const statement = `mutation UpdateSubscriber($input: UpdateSubscriberInput!, $condition: ModelSubscriberConditionInput) {
        updateSubscriber(input: $input, condition: $condition) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubscriberMutation>response.data.updateSubscriber;
  }
  async DeleteSubscriber(
    input: DeleteSubscriberInput,
    condition?: ModelSubscriberConditionInput
  ): Promise<DeleteSubscriberMutation> {
    const statement = `mutation DeleteSubscriber($input: DeleteSubscriberInput!, $condition: ModelSubscriberConditionInput) {
        deleteSubscriber(input: $input, condition: $condition) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubscriberMutation>response.data.deleteSubscriber;
  }
  async CreateLead(
    input: CreateLeadInput,
    condition?: ModelLeadConditionInput
  ): Promise<CreateLeadMutation> {
    const statement = `mutation CreateLead($input: CreateLeadInput!, $condition: ModelLeadConditionInput) {
        createLead(input: $input, condition: $condition) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLeadMutation>response.data.createLead;
  }
  async UpdateLead(
    input: UpdateLeadInput,
    condition?: ModelLeadConditionInput
  ): Promise<UpdateLeadMutation> {
    const statement = `mutation UpdateLead($input: UpdateLeadInput!, $condition: ModelLeadConditionInput) {
        updateLead(input: $input, condition: $condition) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLeadMutation>response.data.updateLead;
  }
  async DeleteLead(
    input: DeleteLeadInput,
    condition?: ModelLeadConditionInput
  ): Promise<DeleteLeadMutation> {
    const statement = `mutation DeleteLead($input: DeleteLeadInput!, $condition: ModelLeadConditionInput) {
        deleteLead(input: $input, condition: $condition) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLeadMutation>response.data.deleteLead;
  }
  async GetSubscriber(id: string): Promise<GetSubscriberQuery> {
    const statement = `query GetSubscriber($id: ID!) {
        getSubscriber(id: $id) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubscriberQuery>response.data.getSubscriber;
  }
  async ListSubscribers(
    filter?: ModelSubscriberFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSubscribersQuery> {
    const statement = `query ListSubscribers($filter: ModelSubscriberFilterInput, $limit: Int, $nextToken: String) {
        listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            firstName
            lastName
            email
            phone
            address
            birthday
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubscribersQuery>response.data.listSubscribers;
  }
  async GetLead(id: string): Promise<GetLeadQuery> {
    const statement = `query GetLead($id: ID!) {
        getLead(id: $id) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLeadQuery>response.data.getLead;
  }
  async ListLeads(
    filter?: ModelLeadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLeadsQuery> {
    const statement = `query ListLeads($filter: ModelLeadFilterInput, $limit: Int, $nextToken: String) {
        listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            fullName
            firstName
            lastName
            email
            phone
            website
            address
            birthday
            notes
            iquiry
            bookCall
            scheduledCall
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLeadsQuery>response.data.listLeads;
  }
  OnCreateSubscriberListener(
    filter?: ModelSubscriptionSubscriberFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSubscriber">>
  > {
    const statement = `subscription OnCreateSubscriber($filter: ModelSubscriptionSubscriberFilterInput) {
        onCreateSubscriber(filter: $filter) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSubscriber">>
    >;
  }

  OnUpdateSubscriberListener(
    filter?: ModelSubscriptionSubscriberFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSubscriber">>
  > {
    const statement = `subscription OnUpdateSubscriber($filter: ModelSubscriptionSubscriberFilterInput) {
        onUpdateSubscriber(filter: $filter) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSubscriber">>
    >;
  }

  OnDeleteSubscriberListener(
    filter?: ModelSubscriptionSubscriberFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSubscriber">>
  > {
    const statement = `subscription OnDeleteSubscriber($filter: ModelSubscriptionSubscriberFilterInput) {
        onDeleteSubscriber(filter: $filter) {
          __typename
          id
          firstName
          lastName
          email
          phone
          address
          birthday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSubscriber">>
    >;
  }

  OnCreateLeadListener(
    filter?: ModelSubscriptionLeadFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLead">>
  > {
    const statement = `subscription OnCreateLead($filter: ModelSubscriptionLeadFilterInput) {
        onCreateLead(filter: $filter) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLead">>
    >;
  }

  OnUpdateLeadListener(
    filter?: ModelSubscriptionLeadFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLead">>
  > {
    const statement = `subscription OnUpdateLead($filter: ModelSubscriptionLeadFilterInput) {
        onUpdateLead(filter: $filter) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLead">>
    >;
  }

  OnDeleteLeadListener(
    filter?: ModelSubscriptionLeadFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLead">>
  > {
    const statement = `subscription OnDeleteLead($filter: ModelSubscriptionLeadFilterInput) {
        onDeleteLead(filter: $filter) {
          __typename
          id
          fullName
          firstName
          lastName
          email
          phone
          website
          address
          birthday
          notes
          iquiry
          bookCall
          scheduledCall
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLead">>
    >;
  }
}
